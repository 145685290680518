







import { Component, Vue, Prop } from "vue-property-decorator";
import Gravatar from "vue-gravatar";

@Component({
  components: {
    VGravatar: Gravatar,
  },
})
export default class Avatar extends Vue {
  @Prop() model!: HasMedia;

  @Prop({ default: 20 }) size!: number;

  get accountEmail(): string {
    if (!this.model) {
      return "unknown";
    }

    if (this.model.email) {
      return this.model.email;
    }

    if (this.model.title) {
      return this.model.title;
    }

    return "unknown";
  }

  get accountName(): string {
    if (!this.model) {
      return "unknown";
    }

    if (this.model.first_name) {
      return this.model.first_name;
    }

    if (this.model.title) {
      return this.model.title;
    }

    return "unknown";
  }

  get accountImage(): string | null {
    if (!this.model.media) {
      return null;
    }

    return this.model.media.path;
  }
}
